import React from 'react'
import { Alert, Button, Divider, Image, Space, Typography } from 'antd'
import { Link } from 'react-router-dom';
import SignInButton from './SignInButton'

const { Title } = Typography;

const Login = () => {
    return (
        <>
            {/* <Button variant="secondary">Request Profile Information</Button>
            <h5 className="card-title">Please sign-in to see your profile information.</h5> */}

            <div className="layout">
                <div className='main'>
                    <div className="topbar">
                        <div className="topbarWrapper">
                            <div className="topLeft">
                                <div className="logo">
                                    {/* <Space> */}
                                        {/* <WechatOutlined style={{ fontSize: 24, color: 'red' }} /> */}

                                        {/* <Image
                                            height={25}
                                            src='https://pafeblobprodby.blob.core.windows.net/20230505t000000z86736b20dd2a46e3baa33908b177b847/3403fd8f-4aa1-49fc-b4ca-5b3b0c08b3bd/web/Assets/Images/42fe9a61-6114-4269-b365-1f6e6ce6d6ec.png?sv=2018-03-28&sr=c&sig=TtJbePfz%2BDN0NaZfcY8a0%2FGe91e08OTF6oiQiEVWFB0%3D&se=2023-05-15T04%3A00%3A00Z&sp=rl'
                                        /> */}
                                        {/* <span style={{ color: '#fff' }}>
                                            DuPont SmartChat
                                        </span> */}
                                        {/* <span> GenAI-Chat</span> */}
                                    {/* </Space>  */}
                                    <div className="logo" style={{ display: 'block' }}>
                                    <span style={{ color: 'rgb(255, 255, 255)', display: 'block' }}>DuPont SmartChat</span>
                                    <span style={{ color: 'rgb(255, 255, 255)', fontSize: '10px', float: 'right' }}>Powered by GPT-4o mini</span>
                                    </div>
                                </div>

                            </div>
                            <div className="topRight">
                            <div className="topbarIconContainer">
                        <i className="bx bx-support" style={{ fontSize: '1.2em', marginRight: 5 }} />
                        <Link to="mailto:DL-R-GLOBAL-genaichat-support@dupont.com?subject=DuPont SmartChat Support&body="><span>Support</span></Link>
                    </div>

                    <div className="topbarIconContainer">
                        <i className="bx bx-bulb" style={{ fontSize: '1.5rem' }} />
                        <Link target="_blank" to="https://dupont.sharepoint.com/sites/ITMatters/SitePages/Generative-AI-at-DuPont.aspx"><span>Learn more & submit ideas</span></Link>
                    </div>
                                
                    <div className="topbarIconContainer">
                        <Image
                            height={24}
                            src='../assets/DuPont_tm_w.svg'
                            preview={false}
                        />
                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="layout__content">
                        <div className="layout__content-main">
                            <div style={{ position:'fixed', top:'50%', textAlign:'center',width:'100%' }}>
                                <div style={{ lineHeight: 2 }}>
                                    {/* <Title level={2}>401 - Unauthorized</Title> */}

                                    {/* <p>You are not authorized to access the GenAI-chat application.</p> */}

                                    
                                    <div style={{ margin: '20px' }}>
                                        <SignInButton />
                                    </div>
                                    {/* <a style={{ marginTop: 20, color: '#1890ff' }} href='https://dupont.sharepoint.com/:p:/s/ITMatters/Ec81ezT5N3lAurVBUXmHO0YBMixtxn_xrFBim_tXWVR7Jg?e=xitb3K' target='_blank' >Click here for instructions on requesting access.</a> */}
                                    <a style={{ marginTop: 20, color: '#1890ff' }} href='https://dupont.sharepoint.com/:p:/s/ITMatters/Ec81ezT5N3lAurVBUXmHO0YBMixtxn_xrFBim_tXWVR7Jg?e=xitb3K' target='_blank' >Click here for instructions on requesting access.</a>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login